import React, { Component, useState  } from "react"
import Layout from "../../../components/layout"

function SecretCodeGridCharacter(props) {
	var value = props.value;
	var encrypted = props.encrypted;

	var firstChar = value.charCodeAt(0) - 65;

	var horizontalPosition = (Math.floor(firstChar/3)%3);
	var horizontalClass = (horizontalPosition === 0 ? "left" : horizontalPosition === 1 ? "center" : "right")

	var verticalPosition = Math.floor(Math.floor(firstChar / 3) / 3);
	var verticalClass = (verticalPosition === 0 ? "top" : verticalPosition === 1 ? "middle" : "bottom")

	var encryptedclass = (encrypted ? "encrypted" : "");
	var classnames = "showlines " + horizontalClass + " " + verticalClass + " " + encryptedclass;

	var digits = [];
	for (var i = 0; i < value.length; i++) {
		var c = value[i];

		var char = c.charCodeAt(0) - 65;
		var pos = char % 3;
		var classes = "pos" + pos;

		if (encrypted) {
			c = "•"
		}

		digits.push(<span key={"digit" + i} className={classes}>{c}</span>)
	}

	return (<span className={classnames}>{digits}</span>)
}

function SecretCodeCharacter(props) {
	var value = props.value;
	var encrypted = props.encrypted;

	var firstChar = value.charCodeAt(0) - 65;
	var horizontalPosition = (Math.floor(firstChar / 3) % 3);
	var horizontalClass = (horizontalPosition === 0 ? "left" : horizontalPosition === 1 ? "center" : "right")

	var verticalPosition = Math.floor(Math.floor(firstChar / 3) / 3);
	var verticalClass = (verticalPosition === 0 ? "top" : verticalPosition === 1 ? "middle" : "bottom")


	var encryptedclass = (encrypted ? "encrypted" : "");
	var classnames = horizontalClass + " " + verticalClass + " " + encryptedclass;

	var c = value;
	var char = firstChar;
	var pos = char % 3;
	var classes = "pos" + pos;

	if (encrypted) {
		c = "•"
	}
	var content = <span className={classes}>{c}</span>

	return (
		<span className={"secretcode " + encryptedclass}>
			<span className={classnames}>{content}</span>
		</span>
	)
}

class SectionSecretMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			encrypted: true
		}
	}

	encryptButton = () => {
		this.setState({encrypted: (!this.state.encrypted)})
	}

	render() {
		var encrypted = this.state.encrypted;
		return (
			<div>
				<div>
					<SecretCodeCharacter value="H" encrypted={encrypted} />
					<SecretCodeCharacter value="E" encrypted={encrypted} />
					<SecretCodeCharacter value="L" encrypted={encrypted} />
					<SecretCodeCharacter value="L" encrypted={encrypted} />
					<SecretCodeCharacter value="O" encrypted={encrypted} />
				</div>
				<div>
					<SecretCodeCharacter value="W" encrypted={encrypted} />
					<SecretCodeCharacter value="O" encrypted={encrypted} />
					<SecretCodeCharacter value="R" encrypted={encrypted} />
					<SecretCodeCharacter value="L" encrypted={encrypted} />
					<SecretCodeCharacter value="D" encrypted={encrypted} />
				</div>
				<p><button onClick={this.encryptButton}>{encrypted ? "Decrypt" : "Encrypt"}</button></p>
			</div>
		)
	}
}


class House extends Component {
	constructor(props) {
		super(props);

		var numOfLights = 1;
		if (this.props.lights && this.props.lights!="") {
			numOfLights = this.props.lights 
		}

		var lightStatus = [];
		for(var i = 0; i < numOfLights; i++) {
			lightStatus[i] = true;
		}
		console.log("numOfLights", numOfLights)
		console.log("lightStatus", lightStatus)

		this.state = {
			numOfLights: numOfLights,
			lightStatus: lightStatus
		}
	}

	setOn = (i) => {
		var lightStatus = this.state.lightStatus;
		console.log("turn lights1:", lightStatus)

		lightStatus[i] = !lightStatus[i];
		console.log("turn lights2:", lightStatus)

		this.setState({ lightStatus })
		console.log("i:", i);
		console.log("turn lights3:", lightStatus)
	}

	render() {
		var windows = [];
		var lights = [];
		var sws = [];
		var messages = [];

		var c_messages = [
			{ on: "Meet at the park! Yah \\0/", off: "Nope, not meeting yo!"}
		]
		var labels = [
			"Bedroom Lights",
			"Kitchen Lights"
		]
		console.log("numOfLights:", this.state.numOfLights)

		for (var i = 0; i < 4; i++) {
			var cssindx = i + 1;

			var light_on = false;
			if (this.state.lightStatus.length > i) {
				light_on = this.state.lightStatus[i];
			}

			var class_light_status = (light_on ? "light_on" : "")
			var classnames = "window window" + cssindx + " " + class_light_status;
			var window = (<div className={classnames}></div>)
			windows.push(window);

			classnames = "light light" + cssindx;
			lights.push(<div className={classnames}></div>);
		}


		for(var i = 0; i < this.state.numOfLights; i++) {
			var cssindx = i + 1;

			var light_on = this.state.lightStatus[i];
			var label = labels[i] + " " + (light_on ? "ON" : "OFF");
			console.log("label:", label)
			console.log("i:", i)

			var sw = (
			<div>
					<div className="lightswitch toggle-wrapper all">
						<div className="toggle transparent all">
							<input id="transparent" className="all" type="checkbox" onClick={() => this.setOn(i)}/>
							<label className="toggle-item all" for="transparent"></label>
						</div>
					</div>
					<p>{label}</p>
			</div>)
			sws.push(sw);

//			var message = (light_on ? c_messages[i].on : c_messages[i].off)
//			messages.push(message);
		}


		return(
			<div className="house_container">
				<div className="house">
					<div className="roof"></div>
					<div className="base"></div>
					<div className="door"></div>
					{windows}
					
				</div>

				<div className="lightswitchtable">
					{sws}
				</div>
				<p className="speech-bubble">{messages}</p>
			</div>
		)
	}
}



export default function Blog({data}) {
	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">CS Refresher – Introduction to Bits</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">May 18, 2021</time>
					</div>
				</header>
				<div className="entry-content">
					<p>This blog series is a rediscovery and exploration of computer science topics that ignited my passion for coding.</p>
					<hr />
					<p>As a child, I loved sending secret written messages to my friends.  I would use various symbols and methods to encrypt my message into a hidden secret and then share them.  One of my favorites is a substitution cipher that replaces each letter with a symbol.  The symbols follow a tic-tac-toe board where each square contains up to 3 characters starting with a, b, c in the top left square:</p>

					<div className="secretcodegrid">
						<SecretCodeGridCharacter value="ABC" />
						<SecretCodeGridCharacter value="DEF" />
						<SecretCodeGridCharacter value="GHI" />
						<SecretCodeGridCharacter value="JKL" />
						<SecretCodeGridCharacter value="MNO" />
						<SecretCodeGridCharacter value="PQR" />
						<SecretCodeGridCharacter value="STU" />
						<SecretCodeGridCharacter value="VWX" />
						<SecretCodeGridCharacter value="YZ" />
					</div>

					<p>For each character in my message, I draw the corresponding box the letter is located and I place a dot in the position of the character:</p>

					<p>Thus, 
						A becomes <SecretCodeCharacter value="A" encrypted={true} />, 
						B is <SecretCodeCharacter value="B" encrypted={true} />, 
						C is <SecretCodeCharacter value="C" encrypted={true} />,
						all the way to Z as <SecretCodeCharacter value="Z" encrypted={true} /></p>

					<p>
						Can you decrypt the message?
					</p>
					<SectionSecretMessage />

					<p>When coming up with methods to encrypt secret messages, we need to know:</p>
					<ol>
						<li>
							How is the message transmitted?
						</li>
						<li>
							What are the rules for the secret code?
						</li>
						<li>
							Any other important context for the message?
						</li>
					</ol>
					<p>For my favorite cipher above:</p>
					<ol>
						<li>
							The message is transmitted via paper.
						</li>
						<li>
							We will use two way substitution replacing each character to a matching symbol (and back again)
						</li>
						<li>
							The message contains only alphabetical characters
						</li>
					</ol>

					<p>The way a message is transmitted plays an important role in deciding what we use for symbols in our message.</p>

					<h2>Binary Encryption</h2>

					<p>Let's say we want to send a secret message to our best friend next door to indicate if we will meet in the park after school.  We want to transmit that message visually so we'll use our bedroom light.  Since the light only has two possible states, on and off, it's a binary decision.  When the light is on, we meet at the park.  When the light is off, we don't.</p>

					{/* <House lights="1" /> */}

					<p>Awesome, now we can send secret messages to one another without our parents finding out.</p>

					<p>What if we want to send another message?  What if we want to coordinate wearing the same Flintstone's t-shirt to school?</p>

					<p>Well, one light gives us a binary choice so every time we met at the park, we also wear the Flintstones t-shirt.  And every time we didn't meet at the park, we also didn't wear the same flintstones t-shirt.</p>
					<p>What if we want the option to meet at the park but not wear the same t-shirt, or wear the same t-shirt but not meet at the park?  Since there are 4 possible scenarios, we can't do it with just one light.  We'll need to add another light!</p>

					<House lights="2" />

					<p>

Bedroom Light
Context
On
Wear the Flintstones T-Shirt
Off
Wear something different
					</p>
					<p>Or when we exchange candy the next day, we agree the light off means "Bring 1 piece of candy" and light on means "Bring 2 pieces of candy".
					</p>
					<p>

Bedroom Light
Context
On
Bring 2 pieces of candy
Off
Bring 1 piece of candy
					</p>
					<p>As you can see, we can send pretty cool secret messages back and forth with just one lightbulb!</p>
					<p>What if we want to send all 3 messages above using just our bedroom light?  We could, but since we only have one light mapped to 3 messages, every time we met at the park, we also wore the same t-shirt and brought 2 pieces of candy.</p>
					<p>

Bedroom Light
Message
On
Yes, meet at the park after school
Yes, wear the Flintstones T-Shirt
Bring 2 pieces of candy
Off
No, we don't meet
No, wear a different T-shirt
Bring 1 piece of candy
					</p>
					<p>With only one lightbulb, there is ambiguity on which message we are transmitting.  If we want to meet at the park but not wear the same t-shirt, we'll need to add another indicator, perhaps a different light in the house.  We can use the bedroom light for the "meet in the park", the front porch light for the "what t-shirt to wear" and the kitchen light for the "candy exchange" message.</p>
					<p>

Bedroom Light
Front Door Light
Kitchen Light

					</p>
					<p>

On
Off
On
Off
On
Off
Message
Yes
No








Meet at park after school




Yes
No




Wear the Flintstones T-Shirt








2 pieces
1 piece
Number of candy to exchange

					</p>
					<p>Now when we set our lights we can determine if we're meeting at the park, what t-shirt to wear and how many candies to exchange.</p>
					<p>

Bedroom Light
Front Door Light
Kitchen Light
Message
On
Off
On
Meet at park after school
Do not wear our Flintstones t-shirt
We will exchange 2 pieces of candy
Off
On
Off
Do not meet at the park
Wear our Flintstones t-shirt
We will exchange 1 piece of candy

					</p>
					<p>So what does this all have to do with computers?</p>
					<p>
						Well, at the smallest scale, computers store information similar to a secret code.  When we decode this information, we can decode the hidden message.  At its core, computers are powered by an electric current, and that current can either be "On" or "Off", similar to our bedroom light.  In computers, our bedroom light is equivalent to what is called a <strong>Bit</strong>.
					</p>
					<p>
						<strong>A bit is the smallest unit of storage (aka memory) and stores either a 0 or a 1.</strong>
					</p>
					<p>With just a single bit, and an agreed definition on what the values of that bit means, we can decrypt a message with two options.</p>
					<p>What if we want more options for our message?  Similar to our lightbulbs, we can add additional Bits to our message.</p>

					<p>Happy Coding!</p>

				</div>
			</article>
		</Layout>
	)
}

